
<template>
  <div class="divBox">
    <div class="cardBox">
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{details.goodsAmount||0}}</div>
          <div>应收金额</div>
        </div>
      </el-card>
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{details.payAmount||0}}</div>
          <div>实付金额</div>
        </div>
      </el-card>
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{details.discountPrice||0}}</div>
          <div>抵扣金额</div>
        </div>
      </el-card>
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{details.quantity||0}}</div>
          <div>订货数量</div>
        </div>
      </el-card>
    </div>

    

    <el-card style="margin-bottom: 20px">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
      <div>
          <el-form-item label="" prop="type">
            <el-radio-group v-model="tableFrom.type" type="button" @change="getList">
              <div style="display: flex">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="1">积分订单</el-radio-button>
                <el-radio-button label="2">付款订单</el-radio-button>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="提交时间：" prop="time">
            <el-date-picker
              v-model="tableFrom.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(),getDateDetails()"
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div class="mb20 acea-row">
        <el-button
          size="small"
          type="primary"
          class="mb10"
          @click="batchSettlement"
          >批量结算</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="id"
        :default-expand-all="false"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column key="2" type="selection" width="55" />
        <el-table-column label="商品订单号" prop="code" min-width="80">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" min-width="300">
          <template slot-scope="scope">
            <div v-if="scope.row.goodsName">
            <div v-for="(item,index) in scope.row.goodsName.split(',')" :key="index">{{item}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="微信流水号" prop="transactionId" min-width="80">
        </el-table-column>
        <el-table-column label="订货数量" prop="quantity" min-width="80">
        </el-table-column>
        <el-table-column label="已发货数量" prop="sentQuantity" min-width="80">
        </el-table-column>
        <el-table-column
          label="应收金额(元）"
          prop="goodsAmount"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          label="抵扣金额(元）"
          prop="discountPrice"
          min-width="80"
        >
        </el-table-column>
        <el-table-column label="实付金额(元）" prop="payAmount" min-width="80">
        </el-table-column>
        <el-table-column label="订单结算状态" prop="billStatus" min-width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.billStatus == 0 ? "未结算" : "已结算" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="60" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.billStatus == 0"
              type="text"
              size="small"
              @click="beforSettlement(scope.row.id)"
              >结算
            </el-button>
            <el-button type="text" size="small" @click="lookView(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  storeOrderPageList,
  storeBillAdd,
  storeOrderCount
} from "@/api/finance/finance";
import moment from "moment";
import { getSupplierListAll } from "@/api/supplier/supplier";
export default {
  name: "ProductClassify",
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      supplierList: [],
      selectionId: [], //选中得id
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        time: [],
        type:'',
        storeId:this.$store.state.user.userInfo.storeId
      },
      form: {},
      details:{}
    };
  },
  mounted() {
    this.getList();
    this.getDateDetails()
  },
  methods: {
    //获取头部数据
    getDateDetails(){
      storeOrderCount(this.tableFrom.storeId).then((res) => {
        if(res.data){
          this.details = res.data
        }else{
          this.details = {}
        }
  
      });
    },
    lookView(val) {
      this.$router.push({
        path: "/finance/orderDetails",
        query: { id: val, type: "storeOrders" },
      });
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.tableFrom.supplierId = this.supplierList[0].id;
      this.getList();
    },
    //多选得id
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const data = [];
      this.multipleSelection.map((item) => {
        data.push(item.id);
      });
      this.selectionId = data;
    },
    //批量
    beforSettlement(id) {
      this.$confirm(`是否结算`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            storeBillAdd({
              id: this.tableFrom.storeId,
              orderIds: [id],
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.getList();
              }
            });
          }
        },
      });
    },
    //批量结算
    batchSettlement() {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      storeBillAdd({
        id: this.tableFrom.storeId,
        orderIds: this.selectionId,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      storeOrderPageList({ ...this.tableFrom, time: "" })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.elCard {
  width: 23.5%;
}
.price {
  font-size: 30px;
}
</style>
